<template>
    <span>
        <span v-if="subDomainName">
            <custom-spinner-logo :text_trad="text_trad" />
        </span>
        <span v-else>
            <default-spinner-logo :text_trad="text_trad" />
        </span> 
    </span>
</template>

<script type="text/javascript">
   import { getSubDomainName } from "@/assets/js/common.js"

    export default {
        props: {
            text_trad: {
                type: String,
                default: () => ('global.chargement')
            },
        },
        components: {
            defaultSpinnerLogo: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            customSpinnerLogo: () => {
                const subDomain = getSubDomainName()
                return import(`@/components/Logos/custom/${subDomain}/LoadingSpinner_35`)    
            }
        }
    }
</script>
